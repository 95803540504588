import React, { useState, useEffect } from 'react'
import { useGlobal } from '../../contexts/GlobalContext';
import axios from 'axios'
import Company from './Companies/Company';

const Companies = () => {
    const { setIsLoading, setError, popupActive, setPopupActive, setPopupElements, setPopupData } = useGlobal();
    const [isRetrieved, setIsRetrieved] = useState(false);
    const [companyList, setCompanyList] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);

    useEffect(() => {
        if(popupActive === false && selectedCompany === null)
        {
            getCompanies();
        }
    }, [popupActive, selectedCompany]);

    const getCompanies = async () => {
        try {
            setIsLoading(true)
            const response = await axios.post(
                '/api/distributor/companies',
                { withCredentials: true }
            )

            if (response.status === 200) {
                setCompanyList(response.data);
                setIsRetrieved(true);
            } else {
                setError(response.data.message || 'Wystąpił błąd!');
            }
        } catch (error) {
            setError('Wystąpił błąd!');
        } finally {
            setIsLoading(false);
        }
    }

    const addCompanyPopup = () => {
        const customPopupElements = {
            title: "Dodaj firmę",
            form: {
                endpoint: "/api/distributor/companies/add",
                inputs:[{label: "Nazwa firmy", type: "text", name: "name"}],
                buttons:[{label: "Dodaj", type: "submit"}]
            }
        };
        const customPopupData = { name: "" };
        setPopupElements(customPopupElements);
        setPopupData(customPopupData);
        setPopupActive(true);
    }
    
    return (
        selectedCompany === null ?
        <div className="Company-list">
            {isRetrieved && companyList && companyList.map((company) => (
                <div onClick = {() => setSelectedCompany(company)} className="Company-card" key={company.id}>
                    <h2>{company.name}</h2>
                    <div className="Company-details">
                        <p>Użytkownicy: {company.members}</p>
                        <p>Przypisane materiały: {company.materials !== undefined ? company.materials : 0}</p>
                        <p>Przypisane cenniki: {company.prices !== undefined ? company.prices : 0}</p>
                    </div>
                </div>
            ))}
            <div className="Distributor-content-footer"><button onClick={() => addCompanyPopup()}>Dodaj firmę</button></div>
        </div>
        : <Company selectedCompany={selectedCompany} setSelectedCompany={setSelectedCompany}/>
    )
}

export default Companies