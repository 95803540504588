import React from 'react'

const Footer = () => {
    return (
        <div className="App-footer">
            <div className="App-inner">
                <div className="App-footer-left">
                    <img src="/app/images/chargein.svg" alt="ChargeIn logo"/>
                    <p className="App-footer-desc">                    
                        ChargeIn oferuje stacje ładowania EV dostosowane zarówno do dużych międzynarodowych firm, jak i użytkowników domowych, zapewniając kompleksowe rozwiązania dla elektromobilności.
                    </p>
                </div>
                <div className="App-footer-right">
                    <div className="App-footer-sub-menu">
                        <h2>Zapraszamy do kontaktu</h2>
                        <p>
                            ChargeIn Holding<br />
                            ul. Domaniewska 52<br />
                            02-672 Warszawa<br /><br />
                            <a href="mailto:biuro@chargein.com"><span>biuro@chargein.com</span></a><br />
                            <a href="tel:+48 664 155 455"><span>+48 664 155 455</span></a>
                        </p>
                    </div>
                    <div className="App-footer-sub-menu">
                        <h2>Spotkajmy się</h2>
                        <ul>
                            <li><a href="https://www.facebook.com/p/ChargeIn-61560682437690/?fref=mentions&paipv=0&eav=AfaFmg95-axbXYEnQwjkmsNT15lppMVXDaIDCKcziZ99eGgu1cfqOA45D4jGCdHgFi4&_rdr">Facebook</a></li>
                            <li><a href="https://www.instagram.com/chargein_com/">Instagram</a></li>
                            <li><a href="https://pl.linkedin.com/company/chargein-elektromobilnosc">LinkedIn</a></li>
                        </ul>
                    </div>
                    <div className="App-footer-sub-menu">
                        <h2>Informacje</h2>
                        <ul>
                            <li><a href="https://www.chargein.com/doc/">Regulamin</a></li>
                            <li><a href="https://www.chargein.com/polityka-prywatnosci/">Polityka prywatności</a></li>
                            <li><a href="https://www.chargein.com/cookies/">Polityka cookies</a></li>
                            <li><a href="https://www.chargein.com/do-pobrania/">Do pobrania</a></li>
                        </ul>
                    </div>
                    <div className="App-footer-sub-menu">
                        <h2>Menu</h2>
                        <ul>
                            <li><a href="https://www.chargein.com/dla-kierowcow/">Dla kierowców</a></li>
                            <li><a href="https://www.chargein.com/dla-domu/">Dla domu</a></li>
                            <li><a href="https://www.chargein.com/dla-biznesu/">Dla biznesu</a></li>
                            <li><a href="https://www.chargein.com/kim-jestesmy/">Kim jesteśmy</a></li>
                            <li><a href="https://www.chargein.com/kariera/">Kariera</a></li>
                        </ul>
                    </div>
                </div>
                <div className="App-footer-bottom">
                    <span className="App-footer-bottom-left">
                        Wszelkie prawa zastrzeżone © 2023-2025 ChargeIn
                    </span>
                    <div className="App-footer-bottom-right">
                        <span>Jesteśmy członkiem PSNM</span>
                        <img className="App-psnm-logo" src="/app/images/psnm.png" alt="Logo PSNM"/>
                        <span>Jesteśmy członkiem PIRE</span>
                        <img className="App-pire-logo" src="/app/images/pire.png" alt="Logo PIRE"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;