import React, { useState } from 'react'
import axios from 'axios'

const Orders = ({isLoading}) => {
    const [error, setError] = useState(null);

    return (
        <h2>Orders section</h2>
    )
}

export default Orders