import React, { useState } from "react"
import '../css/Tooltip.css'

export default function Tooltip({ text, children }) {
    const [isVisible, setIsVisible] = useState(false);
    return(
        <div
            className="App-tooltip"
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
        >
            {children}
            {isVisible && <div className="App-tooltip-text">{text}</div>}
        </div>
    )
}