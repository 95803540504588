import React, { useEffect, useState } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { useGlobal } from '../contexts/GlobalContext'
import  Materials  from './Distributor/Materials'
import  Prices  from './Distributor/Prices'
import  Reservations  from './Distributor/Reservations'
import  ReservationsHistory  from './Distributor/ReservationsHistory'
import  Orders  from './Distributor/Orders'
import  Companies  from './Distributor/Companies'
import '../css/Distributor.css'

const DistributorMain = () => {
    const { userRole } = useAuth();
    const [ key, setKey ] = useState(0);
    const [activeTab, setActiveTab] = useState("materials");

    const selectDistributorTab = (tab) => {
        if(activeTab === tab){
            setKey((prev) => prev + 1); 
        }
        setActiveTab(tab);
    }

    return (
        <div className="Distributor-app">
            <div className="Distributor-nav">
                <a className="Distributor-nav-button" onClick={() => selectDistributorTab("materials")}><img src="/app/images/materials.svg" alt="Materials icon"/><span>Materiały</span></a>
                <a className="Distributor-nav-button" onClick={() => selectDistributorTab("prices")}><img src="/app/images/prices.svg" alt="Prices icon"/><span>Aktualne Cenniki</span></a>
                {userRole.includes("admin") && <a className="Distributor-nav-button" onClick={() => selectDistributorTab("reservations")}><img src="/app/images/reservations.svg" alt="Reservations icon"/><span>Rezerwacje</span></a>}
                {userRole.includes("admin") && <a className="Distributor-nav-button" onClick={() => selectDistributorTab("reservations-history")}><img src="/app/images/reservations-history.svg" alt="Reservations history icon"/><span>Historia rezerwacji</span></a>}
                {userRole.includes("admin") && <a className="Distributor-nav-button" onClick={() => selectDistributorTab("orders")}><img src="/app/images/orders.svg" alt="Orders icon"/><span>Zamówienia</span></a>}
                {userRole.includes("admin") && <a className="Distributor-nav-button" onClick={() => selectDistributorTab("companies")}><img src="/app/images/companies.svg" alt="Companies icon"/><span>Firmy</span></a>}
            </div>
            <div className="Distributor-content" key={key}>
                {activeTab === "materials" && <Materials />}
                {activeTab === "prices" && <Prices />}
                {activeTab === "reservations" && <Reservations />}
                {userRole.includes("admin") && activeTab === "reservations-history" && <ReservationsHistory />}
                {activeTab === "orders" && <Orders />}
                {userRole.includes("admin") && activeTab === "companies" && <Companies />}
            </div>
        </div>
    )
}

export default DistributorMain