import React, { useState, useEffect } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import { useGlobal } from '../../../contexts/GlobalContext';
import axios from 'axios'
import Select from "react-select";

const Materials = ({ selectedCompany, onMaterialsRetrieved }) => {
    const { userRole } = useAuth();
    const { setIsLoading, setError, popupActive, setPopupActive, setPopupElements, setPopupData } = useGlobal();
    const [isRetrieved, setIsRetrieved] = useState(false);
    const [assignedMaterialList, setAssignedMaterialList] = useState(null);
    const [unassignedMaterialList, setUnassignedMaterialList] = useState(null);
    const [selectedMaterial, setSelectedMaterial] = useState(null);

    useEffect(() => {
        if(popupActive === false && selectedCompany !== null)
        {
            setSelectedMaterial(null);
            getMaterials();
        }
    }, [popupActive]);

    const getMaterials = async () => {
        try {
            const response = await axios.post(
                '/api/distributor/companies/materials/assigned',
                { id: selectedCompany.id },
                { withCredentials: true }
            )

            if (response.status === 200)
            {
                setAssignedMaterialList(response.data);
                try {
                    const response = await axios.post(
                        '/api/distributor/companies/materials/unassigned',
                        { id: selectedCompany.id },
                        { withCredentials: true }
                    )
        
                    if (response.status === 200) {
                        setUnassignedMaterialList(response.data.map((mat) => ({
                            label: mat.name,
                            value: mat.id
                        })))
                        setIsRetrieved(true);
                        onMaterialsRetrieved();
                    }
                    else
                    {
                        setError(response.data.message || 'Wystąpił błąd!');
                    }
                } catch (error) {
                    setError('Wystąpił błąd!');
                }
            }
            else
            {
                setError(response.data.message || 'Wystąpił błąd!');
            }
        } catch (error) {
            setError('Wystąpił błąd!');
        } finally {
            setIsLoading(false);
        }
    }
    const assignMaterial = (material) => {
        if(selectedMaterial !== null)
        {
            const customPopupElements = {
                title: "Czy chcesz przypisać materiał?",
                form: {
                    endpoint: `/api/distributor/materials/assign`,
                    buttons:[{label: "Tak", type: "submit"}, {label: "Nie", type: "button"}]
                }
            };
            const customPopupData = { fileId: material.value, company: selectedCompany.id };
            setPopupElements(customPopupElements);
            setPopupData(customPopupData);
            setPopupActive(true);
        }
    }
    const unassignMaterial = (material) => {
        const customPopupElements = {
            title: "Czy chcesz usunąć przypisanie materiału?",
            form: {
                endpoint: `/api/distributor/materials/unassign`,
                buttons:[{label: "Tak", type: "submit"}, {label: "Nie", type: "button"}]
            }
        };
        const customPopupData = { fileId: material.id, company: selectedCompany.id };
        setPopupElements(customPopupElements);
        setPopupData(customPopupData);
        setPopupActive(true);
    }

    return (
    <div className="Company-tab-content">
        <table className="Material-list">
            <thead>
                <tr>
                    <th>Nazwa</th>
                    <th>Typ pliku</th>
                    <th>Użytkownik</th>
                    <th>Data przypisania</th>
                    <th style={{width: "51px"}}></th>
                </tr>
            </thead>
            <tbody>
            {isRetrieved && (assignedMaterialList.length > 0 ? assignedMaterialList.map((mat) => (
                <tr key={mat.id}>
                    <td><div className="Material-list-parameter hide-overflow">{mat.name}</div></td>
                    <td><div className="Material-list-parameter">{mat.type}</div></td>
                    <td><div className="Material-list-parameter">{mat.user}</div></td>
                    <td><div className="Material-list-parameter">{mat.formatted_date}</div></td>
                    <td><div className="Material-list-actions">
                        <img onClick={() => unassignMaterial(mat)} src="/app/images/remove.svg" alt=""/>
                    </div></td>
                </tr>
            )) : (
                <tr>
                    <td>Nie znaleziono materiałów</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            ))}
            </tbody>
        </table>
        {isRetrieved && <div className="Material-select">
            <Select
                styles={{
                    control: (base, state) => ({
                        ...base,
                        minHeight: 47,
                        border: state.isFocused ? '1px solid #4c187f' : '1px solid #C9C9C9',
                        '&:hover': {
                            border: '1px solid #4c187f'
                        },
                        boxShadow: 'none',
                        cursor: 'pointer'
                    }),
                    input: (base, state) => ({
                        ...base,
                        color: '#4c187f'
                    }),
                    noOptionsMessage: (base, state) => ({
                        ...base,
                        color: '#4c187f'
                    }),
                    singleValue: (base, state) => ({
                        ...base,
                        color: '#4c187f'
                    }),
                    valueContainer: (base, state) => ({
                        ...base,
                        padding: '0 0 0 10px',
                    }),
                    option: (base, state) => ({
                        ...base,
                        padding: "15px",
                        backgroundColor: state.isSelected ? '#F2F1FE' : '#fff',
                        color: '#4c187f',
                        '&:hover': {
                            backgroundColor: '#F2F1FE',
                            color: '#ec6c3a'
                        },
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                    }),
                    menu: (base, state) => ({
                        ...base,
                        marginTop: 0,
                        marginBottom: 0
                    }),
                    menuList: (base, state) => ({
                        ...base,
                        paddingTop: 0,
                        paddingBottom: 0
                    }),
                    placeholder: (base, state) => ({
                        ...base,
                        color: '#4c187f'
                    }),
                    dropdownIndicator: (base, state) => ({
                        ...base,
                        height: 21,
                        width: 21,
                        color: '#4c187f',
                        padding: 0,
                        '&:hover': {
                            color: '#4c187f'
                        },
                        transition: '0.3s transform ease-in-out',
                        transform: state.selectProps.menuIsOpen && 'rotateX(180deg)'
                    }),
                    indicatorSeparator: (base, state) => ({
                        ...base,
                        display: 'none'
                    })
                }}
                className="File-select-container"
                classNamePrefix="File-select"
                placeholder="Wybierz materiał..."
                noOptionsMessage={() => "Nie znaleziono materiału"}
                options={unassignedMaterialList}
                value={selectedMaterial}
                onChange={(material) => setSelectedMaterial(material)}
            />
            <button onClick={() => assignMaterial(selectedMaterial)}>Przypisz materiał</button>
        </div>}
    </div>
    )
}

export default Materials;