import React, { useState, useEffect } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import { useGlobal } from '../../../contexts/GlobalContext';
import axios from 'axios';
import Users from './Users';
import Materials from './Materials';
import Prices from './Prices';

const Company = ({ selectedCompany, setSelectedCompany }) => {
    const { userRole } = useAuth();
    const { setIsLoading, setError, popupActive, setPopupActive, setPopupElements, setPopupData } = useGlobal();
    const [key, setKey] = useState(0);
    const [isRetrieved, setIsRetrieved] = useState(false);
    const [selectedCompanyTab, setSelectedCompanyTab] = useState(null);
    const lorem = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed placerat sagittis lacus. Nunc quam sapien, gravida id odio eu, dapibus molestie enim. Donec ornare nisl semper, scelerisque sapien sed, hendrerit tortor. Integer quis purus tellus. Phasellus in est quis orci convallis commodo. Maecenas vestibulum neque quis libero ornare dapibus. Cras a velit euismod mauris faucibus lacinia. Fusce rhoncus, augue at laoreet commodo, nisl orci efficitur ante, ut aliquam nisi nulla in diam. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.";

    useEffect(() => {
        if(popupActive === false)
        {
            getCompany();
        }
    }, [popupActive]);

    const getCompany = async () => {
        try {
            const response = await axios.post(
                '/api/distributor/companies/get',
                { id: selectedCompany.id },
                { withCredentials: true }
            )

            if (response.status === 200) {
                if(response.data.length === 0)
                {
                    setSelectedCompany(null);
                }
                else
                {
                    setSelectedCompany(response.data[0]);
                }
            } else {
                setError(response.data.message || 'Wystąpił błąd!');
            }
        } catch (error) {
            setError('Wystąpił błąd!');
        } finally {
            setIsLoading(false);
        }
    }

    const selectCompanyTab = (tab) => {
        if(selectedCompanyTab === tab){
            setKey((prev) => prev + 1); 
        }
        setIsRetrieved(false);
        setSelectedCompanyTab(tab);
    }

    const editCompanyPopup = (company) => {
        const customPopupElements = {
            title: "Edytuj firmę",
            form: {
                endpoint: "/api/distributor/companies/edit",
                inputs:[{label: "Nazwa firmy", type: "text", name: "name"}],
                buttons:[{label: "Zapisz", type: "submit"}]
            }
        };
        const customPopupData = { id: company.id, name: company.name };
        setPopupElements(customPopupElements);
        setPopupData(customPopupData);
        setPopupActive(true);
    }

    const deleteCompanyPopup = (company) => {
        const customPopupElements = {
            title: "Czy chcesz usunąć " + company.name + "?",
            form: {
                endpoint: "/api/distributor/companies/delete",
                buttons:[{label: "Tak", type: "submit"}, {label: "Nie", type: "button"}]
            }
        };
        const customPopupData = { id: company.id };
        setPopupElements(customPopupElements);
        setPopupData(customPopupData);
        setPopupActive(true);
    }
    
    return (
        selectedCompany && <div className="Company-page" key={key}>
            <h2>{selectedCompany.name}</h2>
            <div className="Company-page-actions">
                {<img onClick={() => editCompanyPopup(selectedCompany)} src="/app/images/edit.svg"/>}
                {userRole === "superadmin" && <img onClick={() => deleteCompanyPopup(selectedCompany)} src="/app/images/delete.svg"/>}
            </div>
            <div className={isRetrieved && selectedCompanyTab === "users" ? "Company-tab active" : "Company-tab"}>
                <a onClick={() => selectCompanyTab("users")}>
                    <span>Użytkownicy</span>
                    <img src="/app/images/arrow-down.svg" alt="Collapse or expand users icon"/>
                </a>
                {selectedCompanyTab === "users" && <Users selectedCompany={selectedCompany} onUsersRetrieved={() => setIsRetrieved(true)}/>}
            </div>
            <div className={isRetrieved && selectedCompanyTab === "materials" ? "Company-tab active" : "Company-tab"}>
                <a onClick={() => selectCompanyTab("materials")}>
                    <span>Przypisane materiały</span>
                    <img src="/app/images/arrow-down.svg" alt="Collapse or expand users icon"/></a>
                {selectedCompanyTab === "materials" && <Materials selectedCompany={selectedCompany} onMaterialsRetrieved={() => setIsRetrieved(true)}/>}
            </div>
            <div className={isRetrieved && selectedCompanyTab === "prices" ? "Company-tab active" : "Company-tab"}>
                <a onClick={() => selectCompanyTab("prices")}>
                    <span>Przypisane cenniki</span>
                    <img src="/app/images/arrow-down.svg" alt="Collapse or expand users icon"/></a>
                {selectedCompanyTab === "prices" && <Prices selectedCompany={selectedCompany} onPricesRetrieved={() => setIsRetrieved(true)}/>}
            </div>
        </div>
    )
}

export default Company