import { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from 'axios'

const AuthContext = createContext();

export const  AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSignUpLink, setIsSignUpLink] = useState(false);
  const [signUpParams, setSignUpParams] = useState({
    email: null,
    code: null
  })
  const [userRole, setUserRole] = useState(false);
  const location = useLocation();

  async function checkSession() {
    try {
        const response = await axios.post(
            '/api/distributor/session-check',
            { withCredentials: true }
        )
        setIsAuthenticated(response.data.isAuthenticated);
        setUserRole(response.data.role);
    } catch (error) {
      setIsAuthenticated(false);
    }
  }

  function checkSignUp() {
    const urlParams = new URLSearchParams(location.search);
    const mode = urlParams.get("mode");
    const code = urlParams.get("oobCode");
    const email = urlParams.get("email");

    if(mode === "signIn" && code && email)
    {
      setIsSignUpLink(true);
      setSignUpParams({ email, code: window.location.href });
    }
  }

  useEffect(() => {
    checkSignUp();
    checkSession();

    // const interval = setInterval(checkSession, 5 * 60 * 1000);
    // return () => clearInterval(interval);
  }, []);

  const refreshAuth = async () => {
    await checkSession();
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, userRole, refreshAuth, isSignUpLink, setIsSignUpLink, signUpParams, setSignUpParams }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);