import React, { useState } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { useGlobal } from '../contexts/GlobalContext'
import axios from 'axios'
import '../css/LoginForm.css'

const LoginForm = ({ loginTitle }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { setIsLoading, error, setError } = useGlobal();
    const { refreshAuth } = useAuth();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const response = await axios.post(
                '/api/distributor/login',
                { email, password },
                { withCredentials: true }
            )

            if (response.status === 200) {
                setError(null);
                await refreshAuth();
            } else {
                setError(response.data.message || 'Wystąpił błąd!');
            }
        } catch (error) {
            setError(error.response.data.error || 'Wystąpił błąd!')
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="Login-form">
            <form onSubmit={handleSubmit}>
                <img className='Login-logo' src="/app/favicon.png" alt="ChargeIn"></img>
                <h2 className='Login-header'>{ loginTitle }</h2>
                <div className="Input-wrapper">
                    <img className="Input-icon" src="/app/images/mail.svg" alt="Mail icon"></img>
                    <input
                        type="email"
                        placeholder="E-mail użytkownika"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="Input-wrapper">
                    <img className="Input-icon" src="/app/images/password.svg" alt="Password icon"></img>
                    <input
                        type="password"
                        placeholder="Hasło"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                {error && <p style={{color: 'red'}}>{error}</p>}
                <button type="submit">Zaloguj się</button>
            </form>
        </div>
    )
}

export default LoginForm