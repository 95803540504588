import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { useGlobal } from '../contexts/GlobalContext'
import axios from 'axios'
import '../css/LoginForm.css'

const SignUpForm = () => {
    const navigate = useNavigate();
    const { setIsLoading, error, setError } = useGlobal();
    const { signUpParams, setIsSignUpLink } = useAuth();

    const atLeastOneUppercase = /[A-Z]/g;
    const atLeastOneLowercase = /[a-z]/g;
    const atLeastOneNumeric = /[0-9]/g;
    const atLeastOneSpecialChar = /[#?!@$%^&*-]/g;
    const atLeastTenCharsOrMore= /.{10,}/g;

    const [meter, setMeter] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const passwordTracker = {
        uppercase: password.match(atLeastOneUppercase),
        lowercase: password.match(atLeastOneLowercase),
        number: password.match(atLeastOneNumeric),
        specialChar: password.match(atLeastOneSpecialChar),
        tenCharsOrGreater: password.match(atLeastTenCharsOrMore),
    }

    const passwordStrength = Object.values(passwordTracker).filter(value => value).length;

    const meterColors = ['red', 'orange', '#03a2cc', '#03a2cc', '#0ce052'];

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log("Submit");
        if(passwordStrength < 5)
        {
            setError("Hasło jest za słabe!")
        }
        else if(password !== confirmPassword)
        {
            setError(<span>Te hasła nie pasują do siebie.
                        <br />Spróbuj ponownie.</span>)
        }
        else
        {
            setIsLoading(true);
            try {
                const response = await axios.post(
                    '/api/distributor/companies/users/activate',
                    { email: signUpParams.email, password, code: signUpParams.code }
                )
    
                if (response.status === 200) {
                    setError(null);
                    setIsSignUpLink(false);
                    navigate('/strefa-dystrybutora');
                } else {
                    setError(response.data.message || 'Wystąpił nieoczekiwany błąd!');
                }
            } catch (error) {
                setError(error.response.data.error || 'Wystąpił nieoczekiwany błąd!')
            } finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <div className="Login-form">
            <form onSubmit={handleSubmit}>
                <img className='Login-logo' src="/app/favicon.png" alt="ChargeIn"></img>
                <h2 className='Login-header'>Ustaw hasło do konta</h2>
                <div className="Input-wrapper">
                    <img className="Input-icon" src="/app/images/password.svg" alt="Password icon"></img>
                    <input
                        onFocus={() => setMeter(true)}
                        type="password"
                        placeholder="Hasło"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="new-password"
                        required
                    />
                </div>
                {meter && (
                <div className="Input-password-strength">
                    <div className="Input-password-strength-meter">
                        <div className="Input-password-strength-meter-progress" style={{width: ((passwordStrength / 5 * 100) + "%"), backgroundColor: (meterColors[passwordStrength-1])}}></div>
                    </div>
                    <div className="Input-password-strength-message" style={{marginBottom: (passwordStrength < 5 ? "15px" : "5px")}}>
                    {passwordStrength < 5 && 'Hasło musi zawierać '}
                    {!passwordTracker.uppercase && 'wielką literę, '}
                    {!passwordTracker.lowercase && 'małą literę, '}
                    {!passwordTracker.specialChar && 'specjalny znak, '}
                    {!passwordTracker.number && 'cyfrę, '}
                    {!passwordTracker.tenCharsOrGreater &&
                        '10 lub więcej znaków'}
                    </div>
                </div>
                )}
                <div className="Input-wrapper">
                    <img className="Input-icon" src="/app/images/password.svg" alt="Password confirm icon"></img>
                    <input
                        type="password"
                        placeholder="Powtórz hasło"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                {error && <p style={{margin: '10px 0px 20px 0px', color: 'red'}}>{error}</p>}
                <button type="submit">Zakończ rejestrację</button>
            </form>
        </div>
    )
}

export default SignUpForm