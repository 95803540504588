import React from 'react'
import axios from 'axios'
import { useAuth } from '../contexts/AuthContext'
import { useGlobal } from '../contexts/GlobalContext'

const Header = () => {
    const { setIsLoading, setError } = useGlobal();
    const { isAuthenticated, refreshAuth } = useAuth();

    const handleLogout = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                '/api/logout',
                { withCredentials: true }
            )

            if (response.status === 200) {
                setError(null);
                await refreshAuth();
            } else {
                setError(response.data.message || 'Wystąpił błąd!');
            }
        } catch (error) {
            setError('Wystąpił błąd!');
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="App-header">
            <div className="App-header-top">
                <div className="App-inner">
                    <p>Witaj w panelu dystrybutora</p>
                    <img src="/app/images/profile.svg" alt="Profile icon"/>
                    {isAuthenticated ? <button onClick={handleLogout}>Wyloguj</button> : <button>Zaloguj</button>}
                </div>
            </div>
            <div className="App-header-bottom">
                <div className="App-inner">
                    <div className="App-header-logo">
                        <a href="https://www.chargein.com">
                            <img src="/app/images/logo-menu.svg" alt="ChargeIn logo"></img>
                        </a>
                    </div>
                    <div className="App-header-menu">
                        <a href="https://www.chargein.com/dla-kierowcow/">Dla kierowców</a>
                        <a href="https://www.chargein.com/dla-domu/">Dla domu</a>
                        <a href="https://www.chargein.com/dla-biznesu/">Dla biznesu</a>
                        <a href="https://www.chargein.com/kim-jestesmy/">Kim jesteśmy</a>
                        <a href="https://www.chargein.com/realizacje/">Realizacje</a>
                        <a href="https://www.chargein.com/kariera/">Kariera</a>
                        <a href="https://www.chargein.com/kontakt/">Kontakt</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;