import React, { useState, useEffect } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useGlobal } from '../../contexts/GlobalContext';
import axios from 'axios'

const Prices = () => {
    const { userRole } = useAuth();
    const { setIsLoading, setError, popupActive, setPopupActive, setPopupElements, setPopupData } = useGlobal();
    const [isRetrieved, setIsRetrieved] = useState(false);
    const [pricesList, setPricesList] = useState(null);

    useEffect(() => {
        if(popupActive === false)
        {
            getPrices();
        }   
    }, [popupActive]);

    const getPrices = async () => {
        try {
            setIsLoading(true)
            const response = await axios.post(
                '/api/distributor/prices',
                { withCredentials: true }
            )

            if (response.status === 200) {
                setPricesList(response.data);
                setIsRetrieved(true);
            } else {
                setError(response.data.message || 'Wystąpił błąd!');
            }
        } catch (error) {
            setError('Wystąpił błąd!');
        } finally {
            setIsLoading(false);
        }
    }

    const downloadPrice = async (id) => {
        try {
            setIsLoading(true)
            const response = await axios.get(`/api/distributor/prices/download/${id}`, {
                responseType: "blob",
                withCredentials: true
            })

            const fileContent = response.headers["content-disposition"];

            if(!fileContent) {
                throw new Error("Filename not found in response headers!")
            }

            const matchResult = fileContent.match(/filename="(.+?)"/)
            if(!matchResult) {
                throw new Error("Invalid filename format in response headers!")
            }

            const fileName = matchResult[1];

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
    
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }
    const addPricePopup = () => {
        const customPopupElements = {
            title: "Dodaj cennik",
            form: {
                endpoint: "/api/distributor/prices/add",
                uploads:[{label: "Przeglądaj...", name: "fileUpload"}],
                buttons:[{label: "Wyślij", type: "submit"}]
            }
        };
        const customPopupData = { fileUpload: null };
        setPopupElements(customPopupElements);
        setPopupData(customPopupData);
        setPopupActive(true);
    }
    const editPricePopup = (price) => {
        const customPopupElements = {
            title: "Edytuj cennik",
            form: {
                endpoint: "/api/distributor/prices/edit",
                inputs:[{label: "Nazwa pliku", type: "text", name: "name"}],
                buttons:[{label: "Zapisz", type: "submit"}]
            }
        };
        const name = price.name.substr(0, price.name.lastIndexOf("."));
        const customPopupData = { fileId: price.id, name: name};
        setPopupElements(customPopupElements);
        setPopupData(customPopupData);
        setPopupActive(true);
    }
    const deletePricePopup = (price) => {
        const customPopupElements = {
            title: "Czy chcesz usunąć plik?",
            form: {
                type: "delete",
                endpoint: `/api/distributor/prices/delete/${price.id}`,
                buttons:[{label: "Tak", type: "submit"}, {label: "Nie", type: "button"}]
            }
        };
        const customPopupData = { fileId: price.id };
        setPopupElements(customPopupElements);
        setPopupData(customPopupData);
        setPopupActive(true);
    }

    return (
        <div className="Material-page">
            <table className="Material-list">
                <thead>
                    <tr>
                        <th>Nazwa</th>
                        <th>Typ pliku</th>
                        {userRole !== "user" && <th>Użytkownik</th>}
                        <th>Data przesłania</th>
                        <th style={{width: "110px"}}></th>
                    </tr>
                </thead>
                <tbody>
                {isRetrieved && (pricesList.length > 0 ? pricesList.map((price) => (
                    <tr key={price.id}>
                        <td><div className="Material-list-parameter hide-overflow">{price.name}</div></td>
                        <td><div className="Material-list-parameter">{price.type}</div></td>
                        {userRole !== "user" && <td><div className="Material-list-parameter">{price.user}</div></td>}
                        <td><div className="Material-list-parameter">{price.formatted_date}</div></td>
                        <td><div className="Material-list-actions">
                            <img onClick={() => downloadPrice(price.id)} src="/app/images/download.svg" alt=""/>
                            {userRole !== "user" && <img onClick={() => editPricePopup(price)} src="/app/images/edit.svg" alt=""/>}
                            {userRole !== "user" && <img onClick={() => deletePricePopup(price)} src="/app/images/delete.svg" alt=""/>}
                        </div></td>
                    </tr>
                )) : (
                    <tr>
                        <td style={{padding: 15}}>Nie znaleziono cenników</td>
                        <td></td>
                        {userRole !== "user" && <td></td>}
                        <td></td>
                        <td></td>
                    </tr>
                ))}
                </tbody>
            </table>
            <div className="Distributor-content-footer">{userRole !== "user" && <button onClick={() => addPricePopup()}>Dodaj cennik</button>}</div>
        </div>
    )
}

export default Prices