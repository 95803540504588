import React, { useState, useEffect } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import { useGlobal } from '../../../contexts/GlobalContext';
import axios from 'axios'
import Select from "react-select";

const Prices = ({ selectedCompany, onPricesRetrieved }) => {
    const { userRole } = useAuth();
    const { setIsLoading, setError, popupActive, setPopupActive, setPopupElements, setPopupData } = useGlobal();
    const [isRetrieved, setIsRetrieved] = useState(false);
    const [assignedPricesList, setAssignedPricesList] = useState(null);
    const [unassignedPricesList, setUnassignedPricesList] = useState(null);
    const [selectedPrice, setSelectedPrice] = useState(null);

    useEffect(() => {
        if(popupActive === false && selectedCompany !== null)
        {
            setSelectedPrice(null);
            getPrices();
        }
    }, [popupActive]);

    const getPrices = async () => {
        try {
            const response = await axios.post(
                '/api/distributor/companies/prices/assigned',
                { id: selectedCompany.id },
                { withCredentials: true }
            )

            if (response.status === 200)
            {
                setAssignedPricesList(response.data);
                try {
                    const response = await axios.post(
                        '/api/distributor/companies/prices/unassigned',
                        { id: selectedCompany.id },
                        { withCredentials: true }
                    )
        
                    if (response.status === 200) {
                        setUnassignedPricesList(response.data.map((price) => ({
                            label: price.name,
                            value: price.id
                        })))
                        setIsRetrieved(true);
                        onPricesRetrieved();
                    }
                    else
                    {
                        setError(response.data.message || 'Wystąpił błąd!');
                    }
                } catch (error) {
                    setError('Wystąpił błąd!');
                }
            }
            else
            {
                setError(response.data.message || 'Wystąpił błąd!');
            }
        } catch (error) {
            setError('Wystąpił błąd!');
        } finally {
            setIsLoading(false);
        }
    }
    const assignPrice = (price) => {
        if(selectedPrice !== null)
        {
            const customPopupElements = {
                title: "Czy chcesz przypisać cennik?",
                form: {
                    endpoint: `/api/distributor/prices/assign`,
                    buttons:[{label: "Tak", type: "submit"}, {label: "Nie", type: "button"}]
                }
            };
            const customPopupData = { fileId: price.value, company: selectedCompany.id };
            setPopupElements(customPopupElements);
            setPopupData(customPopupData);
            setPopupActive(true);
        }
    }
    const unassignPrice = (price) => {
        const customPopupElements = {
            title: "Czy chcesz usunąć przypisanie cennika?",
            form: {
                endpoint: `/api/distributor/prices/unassign`,
                buttons:[{label: "Tak", type: "submit"}, {label: "Nie", type: "button"}]
            }
        };
        const customPopupData = { fileId: price.id, company: selectedCompany.id };
        setPopupElements(customPopupElements);
        setPopupData(customPopupData);
        setPopupActive(true);
    }

    return (
    <div className="Company-tab-content">
        <table className="Material-list">
            <thead>
                <tr>
                    <th>Nazwa</th>
                    <th>Typ pliku</th>
                    <th>Użytkownik</th>
                    <th>Data przypisania</th>
                    <th style={{width: "51px"}}></th>
                </tr>
            </thead>
            <tbody>
            {isRetrieved && (assignedPricesList.length > 0 ? assignedPricesList.map((price) => (
                <tr key={price.id}>
                    <td><div className="Material-list-parameter hide-overflow">{price.name}</div></td>
                    <td><div className="Material-list-parameter">{price.type}</div></td>
                    <td><div className="Material-list-parameter">{price.user}</div></td>
                    <td><div className="Material-list-parameter">{price.formatted_date}</div></td>
                    <td><div className="Material-list-actions">
                        <img onClick={() => unassignPrice(price)} src="/app/images/remove.svg" alt=""/>
                    </div></td>
                </tr>
            )) : (
                <tr>
                    <td>Nie znaleziono cenników</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            ))}
            </tbody>
        </table>
        {isRetrieved && <div className="Material-select">
            <Select
                styles={{
                    control: (base, state) => ({
                        ...base,
                        minHeight: 47,
                        border: state.isFocused ? '1px solid #4c187f' : '1px solid #C9C9C9',
                        '&:hover': {
                            border: '1px solid #4c187f'
                        },
                        boxShadow: 'none',
                        cursor: 'pointer'
                    }),
                    input: (base, state) => ({
                        ...base,
                        color: '#4c187f'
                    }),
                    noOptionsMessage: (base, state) => ({
                        ...base,
                        color: '#4c187f'
                    }),
                    singleValue: (base, state) => ({
                        ...base,
                        color: '#4c187f'
                    }),
                    valueContainer: (base, state) => ({
                        ...base,
                        padding: '0 0 0 10px',
                    }),
                    option: (base, state) => ({
                        ...base,
                        padding: "15px",
                        backgroundColor: state.isSelected ? '#F2F1FE' : '#fff',
                        color: '#4c187f',
                        '&:hover': {
                            backgroundColor: '#F2F1FE',
                            color: '#ec6c3a'
                        },
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                    }),
                    menu: (base, state) => ({
                        ...base,
                        marginTop: 0,
                        marginBottom: 0
                    }),
                    menuList: (base, state) => ({
                        ...base,
                        paddingTop: 0,
                        paddingBottom: 0
                    }),
                    placeholder: (base, state) => ({
                        ...base,
                        color: '#4c187f'
                    }),
                    dropdownIndicator: (base, state) => ({
                        ...base,
                        height: 21,
                        width: 21,
                        color: '#4c187f',
                        padding: 0,
                        '&:hover': {
                            color: '#4c187f'
                        },
                        transition: '0.3s transform ease-in-out',
                        transform: state.selectProps.menuIsOpen && 'rotateX(180deg)'
                    }),
                    indicatorSeparator: (base, state) => ({
                        ...base,
                        display: 'none'
                    })
                }}
                className="File-select-container"
                classNamePrefix="File-select"
                placeholder="Wybierz cennik..."
                noOptionsMessage={() => "Nie znaleziono cennika"}
                options={unassignedPricesList}
                value={selectedPrice}
                onChange={(price) => setSelectedPrice(price)}
            />
            <button onClick={() => assignPrice(selectedPrice)}>Przypisz cennik</button>
        </div>}
    </div>
    )
}

export default Prices;