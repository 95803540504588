import React, { useState, useEffect } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import { useGlobal } from '../../../contexts/GlobalContext';
import axios from 'axios'
import Tooltip from '../../../components/Tooltip';

const Users = ({ selectedCompany, onUsersRetrieved }) => {
    const { userRole } = useAuth();
    const { setIsLoading, setError, popupActive, setPopupActive, setPopupElements, setPopupData } = useGlobal();
    const [isRetrieved, setIsRetrieved] = useState(false);
    const [userList, setUserList] = useState(null);

    useEffect(() => {
        if(popupActive === false && selectedCompany !== null)
        {
            getUsers();
        }
    }, [popupActive]);

    const getUsers = async () => {
        try {
            const response = await axios.post(
                '/api/distributor/companies/users',
                { id: selectedCompany.id },
                { withCredentials: true }
            )

            if (response.status === 200) {
                setUserList(response.data);
                setIsRetrieved(true);
                onUsersRetrieved();
            } else {
                setError(response.data.message || 'Wystąpił błąd!');
            }
        } catch (error) {
            setError('Wystąpił błąd!');
        } finally {
            setIsLoading(false);
        }
    }

    const getUserStatus = (status) => {
        switch (status) {
            case 0:
                return "Zaproszony"
            case 1:
                return "Aktywny"
            case 2:
                return "Wyłączony"
            default:
                return ""
        }
    }

    const inviteUser = async (userId) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                '/api/distributor/companies/users/invite',
                { userId },
                { withCredentials: true }
            )

            if (response.status === 200) {
                setIsRetrieved(false);
                getUsers();
            } else {
                setError(response.data.message || 'Wystąpił błąd!');
            }
        } catch (error) {
            setError('Wystąpił błąd!');
        } finally {
            setIsLoading(false);
        }
    }    

    const addUserPopup = () => {
        const customPopupElements = {
            title: "Dodaj użytkownika",
            form: {
                endpoint: "/api/distributor/companies/users/invite",
                inputs:[{label: "Imię i nazwisko", type: "text", name: "name"}, {label: "E-mail", type: "email", name: "email"}],
                buttons:[{label: "Wyślij", type: "submit"}]
            }
        };
        const customPopupData = {id: selectedCompany.id, name: "", email: ""};
        setPopupElements(customPopupElements);
        setPopupData(customPopupData);
        setPopupActive(true);
    }

    const editUserPopup = (user) => {
        const customPopupElements = {
            title: "Edytuj użytkownika",
            form: {
                endpoint: "/api/distributor/companies/users/edit",
                inputs:[{label: "Imię i nazwisko", type: "text", name: "name"}],
                checkboxes:[{label: "Aktywny?", name: "active"}],
                buttons:[{label: "Zapisz", type: "submit"}]
            }
        };
        const customPopupData = {userId: user.id, name: user.name, active: user.status > 1 ? false : true};
        setPopupElements(customPopupElements);
        setPopupData(customPopupData);
        setPopupActive(true);
    }

    const deleteUserPopup = (user) => {
        const customPopupElements = {
            title: "Czy chcesz usunąć " + user.name + "?",
            form: {
                endpoint: "/api/distributor/companies/users/delete",
                buttons:[{label: "Tak", type: "submit"}, {label: "Nie", type: "button"}]
            }
        };
        const customPopupData = {userId: user.id};
        setPopupElements(customPopupElements);
        setPopupData(customPopupData);
        setPopupActive(true);
    }

    return (
    <div className="Company-tab-content">
        <table className="Company-user-list">
            <thead>
                <tr>
                    <th>Imię i nazwisko</th>
                    <th>E-mail</th>
                    <th style={{width: "200px"}}>Status</th>
                    <th style={{width: "60px"}}></th>
                </tr>
            </thead>
            <tbody>
            {isRetrieved && (userList.length > 0 ? userList.map((user) => (
                <tr key={user.id}>
                    <td><div className="hide-overflow">{user.name}</div></td>
                    <td><div>{user.mail}</div></td>
                    <td><div className="Company-user-status">{getUserStatus(user.status)}</div></td>
                    <td><div className="Company-user-list-actions">
                        {user.status > 0 ? <img onClick={() => editUserPopup(user)} src="/app/images/edit.svg" alt=""/> : <Tooltip text="Zaprosić ponownie?"><img onClick={() => inviteUser(user.id)} src="/app/images/retry.svg" alt=""/></Tooltip>}
                        {userRole === "superadmin" && <img onClick={() => deleteUserPopup(user)} src="/app/images/delete.svg" alt=""/>}
                    </div></td>
                </tr>
            )) : (
                <tr>
                    <td>Nie znaleziono użytkowników!</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            ))}
            </tbody>
            <tfoot>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><div className="Company-user-list-actions"><img onClick={() => addUserPopup()} src="/app/images/add.svg" alt=""/></div></td>
                </tr>
            </tfoot>
        </table>
    </div>
    )
}

export default Users;