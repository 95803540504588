import React, { useEffect } from 'react';
import { AuthProvider, useAuth } from '../../contexts/AuthContext';
import { GlobalProvider, useGlobal } from '../../contexts/GlobalContext';
import LoginForm from '../../pages/LoginForm';
import SignUpForm from '../../pages/SignUpForm';
import Header from '../../pages/Header';
import DistributorMain from '../../pages/DistributorMain';
import Footer from '../../pages/Footer';
import SPLoader from '../../components/Spinner';
import Popup from '../../components/Popup';
import '../../css/App.css';
import '../../css/Distributor.css';

function MainContent() {
    const { isAuthenticated, isSignUpLink } = useAuth();
    const { setIsLoading, isLoading, popupActive, setPopupActive, setPopupElements } = useGlobal();
    useEffect(() => {
        document.title = 'Panel dystrybutora';
        setIsLoading(true);
        setTimeout(() => setIsLoading(false), 1000);
    }, []);

    return (
        <div className="App">
            {popupActive && <Popup/>}
            {<Header/>}
            <div className="Distributor-page">
                {isLoading && <SPLoader transparency={0} />}
                {isAuthenticated ? <DistributorMain/> : (isSignUpLink ? <SignUpForm/> : <LoginForm loginTitle={"Panel dystrybutora"}/>)}
            </div>
            {<Footer/>}
        </div>
    )
}

const Distributor = () => {
    return (
        <AuthProvider>
            <GlobalProvider>
                <MainContent />
            </GlobalProvider>
        </AuthProvider>
    )
}

export default Distributor