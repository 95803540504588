import { createContext, useContext, useState } from "react";

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [popupActive, setPopupActive] = useState(false);
  const [popupElements, setPopupElements] = useState(null);
  const [popupData, setPopupData] = useState(null);
  const [error, setError] = useState(null);

  return (
    <GlobalContext.Provider value={{ isLoading, setIsLoading, popupActive, setPopupActive, popupElements, setPopupElements, popupData, setPopupData, error, setError }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => useContext(GlobalContext);