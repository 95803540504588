import React from "react"
import axios from 'axios'
import { useGlobal } from "../contexts/GlobalContext";
import '../css/Popup.css'

export default function Popup() {
    const { isLoading, setIsLoading, setError, popupActive, setPopupActive, popupElements, setPopupElements, popupData, setPopupData } = useGlobal();
    
    const togglePopup = () => {
        setPopupActive(!popupActive);
        setPopupElements(null);
    }

    const updateData = (field, value) => {
        setPopupData(prevData => ({
            ...prevData,
            [field]: value
        }))
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            if (popupElements.form.type !== undefined && popupElements.form.type === "delete")
            {
                const response = await axios.delete(
                    popupElements.form.endpoint,
                    {
                        withCredentials: true
                    }
                );

                if (response.status === 200) {
                    setPopupActive(false);
                } else {
                    setError(response.data.message || 'Wystąpił błąd!');
                }
            }
            else
            {
                //Check if there is a file in the data
                const fileObject = popupData["fileUpload"] instanceof File;
                const isFileUpload = Boolean(fileObject);

                //Set header accordingly
                const headers = isFileUpload
                    ? { "Content-Type": "multipart/form-data" }
                    : { "Content-Type": "application/json" };

                //Prepare the data accordingly
                let requestData = popupData;
                if(isFileUpload)
                {
                    //Add file data
                    const formData = new FormData();
                    formData.append("file", popupData["fileUpload"]);

                    //Add other data
                    Object.entries(popupData).map(([key, value]) => {
                        if(key !== "fileUpload")
                        {
                            formData.append(key, value);
                        }
                    })

                    requestData = formData;
                }

                const response = await axios.post(
                    popupElements.form.endpoint,
                    requestData,
                    {
                        withCredentials: true,
                        headers
                    }
                );

                if (response.status === 200) {
                    setPopupActive(false);
                } else {
                    setError(response.data.message || 'Wystąpił błąd!');
                }
            }
        }
        catch (error)
        {
            setIsLoading(false);
            setError('Wystąpił błąd!')
        }
    }

    return(
        (popupElements != null && !isLoading) && <div className="App-popup">
            <div
                className="overlay"
                onClick={togglePopup}>
            </div>
            <div className="popup-content">
                {popupElements.title !== undefined && <h2>{popupElements.title}</h2>}
                {popupElements.description !== undefined && <p>{popupElements.description}</p>}
                {popupElements.form !== undefined && (<form onSubmit={handleSubmit}>
                    {popupElements.form.inputs !== undefined && popupElements.form.inputs.map((input, index) => (
                        <div className="popup-input" key={index}>
                            <label htmlFor={input.name}>{input.label}</label>
                            <input
                                type={input.type}
                                name={input.name}
                                value={popupData[input.name]}
                                onChange={(e) => updateData(input.name, e.target.value)}
                                required
                            />
                        </div>
                    ))}
                    {popupElements.form.checkboxes !== undefined && popupElements.form.checkboxes.map((checkbox, index) => (
                        <div className="popup-checkbox" key={index}>
                            <label htmlFor={checkbox.name}>{checkbox.label}</label>
                            <input
                                type="checkbox"
                                name={checkbox.name}
                                checked={popupData[checkbox.name]}
                                onChange={() => updateData(checkbox.name, !popupData[checkbox.name])}
                            />
                        </div>
                    ))}
                    {popupElements.form.uploads !== undefined && popupElements.form.uploads.map((upload, index) => (
                        <div className="popup-upload" key={index}>
                            <label htmlFor={upload.name}>{popupData[upload.name] ? popupData[upload.name].name : upload.label}
                                <input
                                    type="file"
                                    id={upload.name}
                                    onChange={(e) => updateData(upload.name, e.target.files[0])}
                                />
                            </label>
                        </div>
                    ))}
                    {popupElements.form.buttons !== undefined && <div className="popup-buttons">
                        {popupElements.form.buttons !== undefined && popupElements.form.buttons.map((button, index) => (
                            <button
                                onClick={button.type === "button" ? togglePopup : undefined}
                                type={button.type}
                                key={index}>{button.label}
                            </button>
                        ))}
                    </div>}
                </form>)}
                <button 
            className="close-popup"
            onClick={togglePopup}>x</button>
            </div>
        </div>
    )
}